/** This contains code useful for the fullcalendar react version */
import CalendarUtils from "./calendar_utils";
import ProgendaUtils from "./progenda_utils";
import {getColor, getColors} from "./colors.js";
import AppointmentStatusLogo from "shared/components/admin/appointment_status_logo";
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import AppointmentPopup from "shared/components/admin/appointment_popup";
import {htmlToElement} from "@fullcalendar/core";


export default class CalendarUtilsReact {
  /**
   * This is needed when creating moments with no timezone
   */
  static get noTimezoneFormat() {
    return "YYYY-MM-DD[T]HH:mm:ss";
  }

  /**
   * This function adds the left/right buttons with the correct font awesome icons
   */
  static addToolbarButtons = ({ onPrev, onNext }) => {
    $(".fc-toolbar")
      .find(".fc-left")
      .html(
        $(
          '<button class="fc-prev-button btn btn-default btn-sm"><span class="fa fa-chevron-left"></span></button>'
        )
      );
    $(".fc-toolbar")
      .find(".fc-right")
      .html(
        $(
          '<button class="fc-next-button btn btn-default btn-sm"><span class="fa fa-chevron-right"></span></button>'
        )
      );
    $(".fc-prev-button").click(() => {
      if (typeof onNext === "function") {
        onPrev();
      }
    });

    $(".fc-next-button").click(() => {
      if (typeof onNext === "function") {
        onNext();
      }
    });
  };

  static get timeDateFormats() {
    return {
      time: "HH:mm:ss",
      /**
       * fullCalendarTime -> used for multiples settings of the fullcalendar
       */
      fullCalendarTime: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        hour12: false
      }
    };
  }

  /**
   * This is executed when rendering the events on calendar
   * if isDragging is true, we should not show any tooltips
   */
  static eventPositioned = ({ event, el, history, updateEventPopupOpened }, isDragging = false) => {
    const { extendedProps } = event;
    const helenaProUser = extendedProps.helenaProUser
    const featureFlagPatientJourney = extendedProps.user && extendedProps.user.featureFlagPatientJourney;
    const elJq = $(el);
    const newEventColor = getColor("eventBackground", helenaProUser);
    let displayIcons = ((event.id !== "toCopy") && (event.id !== "toConfirm") && (event.id !== ""));
    let allowCopyAppointment = true;
    if (extendedProps.isFromParking) {
      // show a transparent light blue background for event when dragging a parked event over calendar
      elJq.html("");
      elJq.css({
        background: getColor("eventWhileDragging", helenaProUser),
        "border-radius": 0,
        "border-color": getColor("eventWhileDragging", helenaProUser),
        width: "100%"
      });
      return;
    }
    if (event.rendering === "background") {
      if (! extendedProps.forPatient) {
        elJq.addClass("not-for-patient");
      }
      if (! extendedProps.independant) {
        elJq.addClass("conditionnal");
      }
    }
    else if (event.id !== "toConfirm") {
      CalendarUtils.tooltipOnDate(
        elJq,
        event,
        true,
        isDragging || event.id === ""
      );
      let color = event.backgroundColor || getColor('canceledEvent', helenaProUser);
      if (event.backgroundColor === "null") {
        color = getColor('canceledEvent')
      }
      elJq.css("cursor", "pointer");
      if (extendedProps.canceled) {
        //elJq.css("cursor", "not-allowed");
        elJq.css("background", ProgendaUtils.getStripedCssEffect(color));
      }
      if (!extendedProps.accessible && event.id !== "") {
        elJq.css("cursor", "not-allowed");
      }
    }
    if (
      event.backgroundColor !== null &&
      event.backgroundColor !== undefined &&
      event.backgroundColor !== ""
    ) {
      elJq.css("background-color", event.backgroundColor);
      elJq.css("border-color", event.backgroundColor);
      try {
        if (ProgendaUtils.isColorBright(event.backgroundColor))
          elJq.css("color", "rgb(92, 92, 92)");
      } catch (e) {}
    }
    if (displayIcons) {
      if (extendedProps.atHome) {
        CalendarUtils.tooltipOnHover(
            elJq,
            "fa fa-home",
            extendedProps.address,
            isDragging
        );
      }

      if (extendedProps.noShow  && (!helenaProUser || (helenaProUser && !featureFlagPatientJourney) || (helenaProUser && featureFlagPatientJourney && !extendedProps.showStatus))) {
        elJq
            .find("div.fc-content .fc-time")
            .prepend('<i class="icon-container fa fa-eye-slash"></i>&nbsp;');
      }
      if (extendedProps.warning) {
        elJq
            .find("div.fc-content .fc-time")
            .prepend('<i class="icon-container fa fa-user-times"></i>&nbsp;');
      }
      if (extendedProps.notes) {
        CalendarUtils.tooltipOnHover(
            elJq,
            "fa fa-sticky-note",
            extendedProps.notes,
            isDragging
        );
      }
      if (extendedProps.patientNotes) {
        CalendarUtils.tooltipOnHover(
            elJq,
            "fa fa-comment",
            extendedProps.patientNotes,
            isDragging
        );
      }
      if (extendedProps.createdBy === "patient") {
        const text =
            extendedProps.createdAt !== undefined &&
            extendedProps.createdAt !== null &&
            extendedProps.createdAt !== ""
                ? I18n.get().t("created_at", {
                  date: ProgendaUtils.dateFormat(
                      extendedProps.createdAt,
                      "long_datetime_format"
                  )
                })
                : "";
        CalendarUtils.tooltipOnHover(
            elJq,
            "fa fa-globe-americas",
            text,
            isDragging
        );
      }
      if (extendedProps.newPatient === true) {
        elJq
            .find("div.fc-content .fc-time")
            .prepend('<i class="icon-container fa fa-star"></i>&nbsp;');
      }
      if (extendedProps.patientMessagesFailed === true) {
        elJq
            .find("div.fc-content .fc-time")
            .prepend('<i class="icon-container fa fa-question"></i>&nbsp;');
      }
      if (extendedProps.teleconsultation === true) {
        elJq
            .find("div.fc-content .fc-time")
            .prepend('<i class="icon-container fa fa-video"></i>&nbsp;');
      }
      if (extendedProps.allowPatientCall) {
        CalendarUtils.patientCallListener(elJq, event);
      }
      if (allowCopyAppointment) {
        CalendarUtils.copyAppointment(elJq, event);
      }
      if (extendedProps.patientArrivedAt !== undefined
          // && !helenaProUser to uncomment when patient journey is done
      ) {
         CalendarUtils.patientArrivedAtListener(elJq, event);
      }
      if (extendedProps.showStatus && helenaProUser && featureFlagPatientJourney){
        elJq.css("margin-left", "10px");
        let fcContent = elJq.find("div.fc-content");
        fcContent.css("margin-left", "3px");
        let currentStatus = CalendarUtils.getCurrentStatus(extendedProps.appointment, extendedProps.center);
        let htmlLogo = ReactDOMServer.renderToString(<AppointmentStatusLogo status={currentStatus} showBgColor={true}/>);
        let htmlLogJq = $(htmlLogo);
        htmlLogJq.css('margin-right','3px');
        elJq.find("div.fc-content .fc-time").prepend(htmlLogJq);

        fcContent.before(`<div class="status-bar ${currentStatus}-bg-color"></div>`);

        let eventDate = new Date(extendedProps.initialStart*1000).setHours(0, 0, 0, 0);
        let today = new Date().setHours(0, 0, 0, 0);
        if (eventDate === today) {
            this.updateAppointmentStatusBar(extendedProps, fcContent);
        }

        const popupElement = document.createElement('div');
        popupElement.id = 'event-' + event.id;
        popupElement.className = 'popup-events';
        popupElement.style.display = 'none';
        $('.fc-body')[0].prepend(popupElement);
        ReactDOM.render(<AppointmentPopup history={history} updateEventPopupOpened={updateEventPopupOpened} event={event} extendedProps={extendedProps} currentStatus={currentStatus}/>, popupElement);

      }
      if (extendedProps.isOnPatientBirthday) {
        CalendarUtils.tooltipOnHover(
            elJq,
            "fa fa-birthday-cake",
            I18n.get().t("its_patient_birthday", {
              contact_designation: extendedProps.contactDesignation
            }),
            isDragging
        );
      }
      if (extendedProps.allowedDisplayContextualLaunch) {
        const elem = $(
            `<span class="icon-container custom-tooltip float-right" data-togle="tooltip" data-placement="bottom" data-trigger="hover"
            title="${I18n.get().t('contextual_launch_url_info')}">&nbsp;<i class="black fa fa-users"></i>&nbsp;</a></span>"`
        );
        elJq
            .find("div.fc-content .fc-time")
            .append(elem);
      }
    }
    if (event.id === "toConfirm") {
      elJq.css("background-color", newEventColor);
      elJq.css("border-color", newEventColor);
      if (elJq.height() > 30)
        elJq.html(
          "<div style='text-align: center; font-size: xx-large;'>+</div>"
        );
      else elJq.html("<div style='text-align: center;'>+</div>");
    } else if (event.id === "toCopy") {
      elJq.append("<div class='fc-bg copied-event'></div>");
    } else {
      /**
       * the new version of calendar doesn't add fc-bg div anymore inside the event element,
       * so we add ot manually.
       * This is a translucent white background which softens the event background color
       * It's not added to the "new event button"
       */
      elJq.append("<div class='fc-bg'></div>");
    }
  };

  /**
   * This removes all tooltips from calendar body when user starts dragging
   */
  static removeTooltipsOnDragging = () => {
    $(".fc-body")
      .find('.tooltip[role="tooltip"]')
      .remove();
  };

  /**
   * This makes the top header (navigation, buttons & calendar dates columns) sticky on top
   */
  static makeHeaderSticky = calendarJqueryElement => {
    if (!$(".fc-head-wrapper-sticky").length && calendarJqueryElement) {
      const fcHeadContainer = $(
        "<div class='fc-head-wrapper-sticky'><table></table></div>"
      );
      $(".fc-head")
        .closest(".fc-view")
        .prepend(fcHeadContainer);
      $(".fc-head").appendTo(fcHeadContainer.find("table"));

      const $calendarMenuOuter = $(".calendar-menu-outer");
      const calendarMenuOuterTop = Math.round(
        parseInt($calendarMenuOuter.css("top").replace("px", ""))
      );
      const calendarMenuTopPadding = Math.round(
        parseInt($calendarMenuOuter.css("padding-top").replace("px", ""))
      );

      calendarJqueryElement.find(".fc-head-wrapper-sticky").css({
        // height: $(".fc-head").height(),
        top:
          $calendarMenuOuter.height() +
          calendarMenuTopPadding * 2 +
          calendarMenuOuterTop
      });
    }
  };

  static makeHeaderStickyTimeout = calendarJqueryElement => {
    setTimeout(() => {
      CalendarUtilsReact.makeHeaderSticky(calendarJqueryElement);
    }, 100);
  };

  static updateAppointmentStatusBar = (extendedProps, fcContent) => {
    setInterval(() => {
      let newStatus = CalendarUtils.getCurrentStatus(extendedProps.appointment, extendedProps.center);
      let statusElement = fcContent.siblings(".status-bar");
      if (statusElement.length) {
        statusElement.remove();
      }
      fcContent.before(`<div class="status-bar ${newStatus}-bg-color"></div>`);

      let statusIcon = fcContent.find(".fc-time .icon-status-circle");
      if (statusIcon.length) {
        statusIcon.parent().remove();
      }
      let htmlString = ReactDOMServer.renderToString(<AppointmentStatusLogo status={newStatus} showBgColor={true}/>);
      let htmlLogJq = $(htmlString);
      htmlLogJq.css('margin-right','3px');
      fcContent.find(".fc-time").prepend(htmlLogJq);

    }, 5000);
  }

  static autoScrollCenter = (dateHours) =>{
    let timeString = ProgendaUtils.dateFormat(dateHours, "long_time_format");

    let closestTr;
    $($("tr[data-time]").not(".fc-minor").get().reverse()).each(function() {
      if ($(this).data("time") <= timeString) {
        closestTr = $(this);
        return false; // break the loop
      }
    });

    if (closestTr && closestTr.length > 0){
      let offset = closestTr.offset().top - $('.fc-body').offset().top
      $('.vertical-scroll.main-content-container').scrollTop(offset);
    }
  }

  static forceDayHeaderToAppear() {
    if ($(".fc-day-header").length == 0) {
      const reference = $(".fc-day-grid .fc-bg .fc-day.fc-widget-content");
      const width = $(".fc-day-grid .fc-axis.fc-widget-content")[0].style.width;
      const colspan = reference.length;
      const dataDate = reference[0].dataset.date;
      const shownDate = moment(dataDate).format('dd D/M')
      const element = htmlToElement(
          '<tr>' +
          `<th class="fc-axis fc-widget-header" style="width: ${width};"></th>` +
          `<th class="fc-day-header fc-widget-header" data-date="${dataDate}" colspan="${colspan}"><span>${shownDate}</span></th>` +
          '</tr>'
      )
      $(".fc-head .fc-head-container .fc-row table thead").prepend(element);
    }
  }
}
