import camelize from "camelize";
import PI from "services/progenda_interface.js";

const Center = function(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      this[prop] = obj[prop];
    }
  }
};

Center.parseCenterHash = function(hash) {
  return new Center(hash);
};

Center.prototype.setCalendars = function(calendars) {
  this.calendars = calendars;
  this.computeCalendarsAttributes();
};

Center.prototype.setResources = function(resources) {
  this.resources = resources;
  this.computeResourcesAttributes();
};

Center.prototype.getCalendarById = function(id) {
  id = typeof id === "string" ? parseInt(id) : id;
  return this.calendars.filter(function(calendar) {
    return calendar.id === id;
  })[0];
};

Center.prototype.getCalendarBySlug = function(slug) {
  return this.calendars.filter(function(calendar) {
    return calendar.slug === slug;
  })[0];
};

Center.prototype.getCalendar = function(idOrSlug) {
  if (typeof idOrSlug === "number" || parseInt(idOrSlug))
    return this.getCalendarById(idOrSlug);
  else return this.getCalendarBySlug(idOrSlug);
};

Center.prototype.getCalendarIds = function() {
  return this.calendars.map(function(calendar) {
    return calendar.id;
  });
};

Center.prototype.computeCalendarsAttributes = function() {
  const contactDesignationCount = {};
  const countryCodeCount = {};
  const enableAppointmentsGroupCount = {};
  this.consultAtHome = false;
  this.birthdateOnPatientForm = false;
  this.calendars.forEach(calendar => {
    calendar.center = this;
    if (calendar.consultAtHome) {
      this.consultAtHome = true; // only one calendar is necessary
    }
    if (calendar.birthdateOnPatientForm) {
      this.birthdateOnPatientForm = true; // only one calendar is necessary
    }
    if (calendar.ssinMatching) {
      this.ssinMatching = true; // only one calendar is necessary
    }
    if (contactDesignationCount[calendar.contactDesignation] === undefined) {
      contactDesignationCount[calendar.contactDesignation] = 0;
    }
    if (countryCodeCount[calendar.countryCode] === undefined) {
      countryCodeCount[calendar.countryCode] = 0;
    }
    if (
      enableAppointmentsGroupCount[calendar.enableAppointmentsGroup] === undefined
    ) {
      enableAppointmentsGroupCount[calendar.enableAppointmentsGroup] = 0;
    }
    contactDesignationCount[calendar.contactDesignation] += 1; // need to find most present value of contactDesignation
    countryCodeCount[calendar.countryCode] += 1; // need to find most present value of contactDesignation
    enableAppointmentsGroupCount[calendar.enableAppointmentsGroup] += 1; // need to find most present value of enableAppointmentsGroup
  });
  this.contactDesignation =
    Object.keys(contactDesignationCount).length > 0
      ? Object.keys(contactDesignationCount).reduce(function(a, b) {
          return contactDesignationCount[a] > contactDesignationCount[b]
            ? a
            : b;
        })
      : "patient";
  this.countryCode =
      Object.keys(countryCodeCount).length > 0
          ? Object.keys(countryCodeCount).reduce(function(a, b) {
            return countryCodeCount[a] > countryCodeCount[b]
                ? a
                : b;
          })
          : "BE";
  this.enableAppointmentsGroup =
    Object.keys(enableAppointmentsGroupCount).length > 0
      ? Object.keys(enableAppointmentsGroupCount).reduce(function(a, b) {
          return enableAppointmentsGroupCount[a] >
            enableAppointmentsGroupCount[b]
            ? a
            : b;
        }) === "true"
      : false;
};

Center.prototype.computeResourcesAttributes = function() {
  this.resources.forEach(resource => {
    resource.center = this;
  });
};

Center.prototype.getFilters = function(forceRefresh) {
  if (this.filterPromise === undefined || forceRefresh) {
    this.filterPromise = new Promise((resolve, reject) => {
      PI.get({
        url: PI.adminCenterFiltersPath(this.slug)
      }).then(({ data }) => {
        resolve(data.filters.map(filterContainer => filterContainer.filter));
      });
    });
  }
  return this.filterPromise;
};

Center.prototype.getResources = function(forceRefresh) {
  if (this.resourcePromise === undefined || forceRefresh) {
    this.resourcePromise = new Promise((resolve, reject) => {
      PI.get({
        url: PI.adminResourcesPath()
      }).then(({ data }) => {
        const centerResourceGroupsIds = this.resourceGroups.map(group => group.id);
        const currentUserResources = data.resources.map(resource => resource.resource);
        resolve(currentUserResources.filter(resource => centerResourceGroupsIds.includes(resource.resourceGroup.id)));
      });
    });
  }
  return this.resourcePromise;
};

Center.prototype.getSpecialities = function() {
  const specialities = {};
  this.calendars.forEach(function(calendar) {
    calendar.specialities.forEach(function(speciality) {
      specialities[speciality.id] = speciality;
    });
  });
  return Object.values(specialities);
};

export default Center;
