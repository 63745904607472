import XSSFilter from "shared/components/xss_filter.tsx";
import ProgendaUtils from "shared/progenda_utils.js";
import Alerts from "shared/components/alerts.js";
import PI from "services/progenda_interface.js";

export default class MiniSuggester extends React.Component {
  setSelectedServiceCodeFromEvent = event => {
    this.setSelectedServiceCode(event.target.value);
  };

  setSelectedServiceCode = serviceCode => {
    const { setSelectedServiceCodes } = this.props;
    if (serviceCode !== "") {
      setSelectedServiceCodes([serviceCode]);
    } else {
      setSelectedServiceCodes([]);
    }
  };

  selectDisabled() {
    Alerts.addError(I18n.get().t("please_select_service"));
  }

  render() {
    const {
      selectedCalendars,
      selectableServices,
      selectedServiceCodes,
      loadingServices,
      suggestions
    } = this.props;

    const calendar = selectedCalendars[0];
    const hasSelectableServices =
      selectableServices !== null && Object.keys(selectableServices).length > 0;
    const shouldSelectService =
      hasSelectableServices && selectedServiceCodes.length === 0;
    let selectedServiceId;
    if (hasSelectableServices && selectedServiceCodes.length !== 0) {
      selectedServiceId = selectableServices.find(
        service => service.label === selectedServiceCodes[0]
      ).id;
    }
    if (selectableServices === null || loadingServices) {
      return <div className="progenda-spinner"></div>;
    }
    return (
      <div>
        {shouldSelectService ? (
          <div>
            <div
              className="comments text-center"
              style={{ marginBottom: "5px" }}
            >
              {I18n.get().t("select_reason")}
            </div>
            {selectableServices.length > 5 ? (
              <select
                onChange={this.setSelectedServiceCodeFromEvent}
                className="service_select form-control clean"
              >
                <option value="">{I18n.get().t("select_service")}</option>
                {selectableServices.map(service => (
                  <option value={service.label} key={service.id}>
                    {service.translation}
                  </option>
                ))}
              </select>
            ) : (
              <div>
                {selectableServices.map(service => (
                  <a
                    onClick={() => {
                      this.setSelectedServiceCode(service.label);
                    }}
                    key={service.id}
                    className="btn btn-block btn-default btn-sm"
                  >
                    {service.translation}
                  </a>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="suggestions">
            {suggestions.length > 0 && (
              <div>
                <div
                  className="comments text-center"
                  style={{ marginBottom: "5px" }}
                >
                  {I18n.get().t("fast_select")}
                </div>
                {suggestions.map(suggestion => (
                  <a
                    href={suggestion.url}
                    rel="nofollow"
                    className="btn btn-block btn-default btn-sm"
                  >
                    {ProgendaUtils.dateFormat(
                      suggestion.start,
                      "long_datetime_format"
                    ).capitalize() +
                      "-" +
                      ProgendaUtils.dateFormat(
                        suggestion.stop,
                        "simple_time_format"
                      )}
                  </a>
                ))}
                <a
                  href={PI.calendarAvailabilitiesPath(calendar.slug, {
                    service_id: selectedServiceId,
                    ...ProgendaUtils.getAllowedParams()
                  })}
                  className="btn btn-block btn-default btn-sm"
                >
                  {I18n.get().t("see_all")}&nbsp;&nbsp;
                  <i className="fa fa-caret-right"></i>
                </a>
              </div>
            )}
            {suggestions.length === 0 && (
              <div>
                <XSSFilter
                  useSpan
                  content={I18n.get().t("no_availabilities_found", {
                    name: calendar.officialNameInASentence
                  })}
                  allowLinks={true}
                />
                &nbsp;
                {calendar.phoneNumber &&
                  calendar.phoneNumber.toString().length && (
                    <span>
                      {I18n.get().t("no_availabilities_found_phone_number", {
                        phone: calendar.phoneNumber
                      })}
                    </span>
                  )}
              </div>
            )}
            {Object.keys(selectableServices).length > 0 && (
              <a
                onClick={() => {
                  this.setSelectedServiceCode("");
                }}
                className="btn btn-block btn-default btn-sm"
                style={{ marginTop: "5px" }}
              >
                <i className="fa fa-caret-left"></i>&nbsp;&nbsp;{I18n.get().t("back")}
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}
