import ServiceSelect from "./service_select.js";

export default class MultipleServiceSelect extends React.Component {
  addService = serviceCode => {
    if (serviceCode) { // replace or add new selection according Appointments group or not
      let selectedServiceCodes = this.props.enableAppointmentsGroup ? [...this.props.selectedServiceCodes, serviceCode] : [serviceCode]
      this.props.setSelectedServiceCodes(selectedServiceCodes);
    }
  };

  removeService = serviceCode => {
    this.props.setSelectedServiceCodes(
      this.props.selectedServiceCodes.filter(code => code !== serviceCode)
    );
  };

  countOccurences = serviceCodes => {
    const occurences = {};
    serviceCodes.forEach(code => {
      occurences[code] = occurences[code] ? occurences[code] + 1 : 1;
    });
    return occurences;
  };

  render() {
    const selectedServiceCode =
      this.props.selectedServiceCodes.length > 0
        ? this.props.selectedServiceCodes[0]
        : "";
    const occurences = this.countOccurences(this.props.selectedServiceCodes);
    return (
      <div className="service-select-simple">
        {this.props.enableAppointmentsGroup && (
          <table className="table table-striped">
            <tbody>
              {Object.keys(occurences).map(serviceCode => {
                const service = this.props.services.find(
                  s => s.label === serviceCode
                );
                return (
                  <tr key={service.label}>
                    <td>{occurences[serviceCode]}x</td>
                    <td>{service.translation}</td>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        this.removeService(serviceCode);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <ServiceSelect
          selectedServiceCode={
            !this.props.enableAppointmentsGroup && selectedServiceCode
          }
          services={this.props.services}
          onChange={this.addService}
          sortServices={true}
        />
      </div>
    );
  }
}
