import ProgendaContext from "shared/progenda_context.js";
import ProgendaUtils from "shared/progenda_utils.js";
import PI from "services/progenda_interface.js";
import { isEqual } from 'lodash'

/*
 *
 * This component passes suggestions as props to its children, using its own props to compute these suggestions.
 *
 * Its props should be of the following structure :
 * selectedServiceCodes = ["serviceCodeA", "serviceCodeB", "serviceCodeA"]
 *
 * We made the following design decisions :
 * -There is no cache. Caching is hard. It's alright if the user has to wait a little bit when changing calendars or services ;
 * -There is no automatic reload for now.
 *
 */

export default class SuggestionProvider extends React.Component {
  constructor(props) {
    super(props);
    //suggestions are automatically loaded on patient side. So starts to true to avoid "No appointment available" message before loading suggestions
    this.state = { suggestions: [], loadingServices: false, loadingSuggestions: ProgendaContext.getContext() !== "ADMIN", requestsInProgress: 0, isMounted: false };
  }

  componentDidMount() {
    this.setState({isMounted: true})
  }

  computeSuggestions() {
    let suggestions = [];
    let promisesCalendarSuggestions = this.props.selectedCalendars.map(calendar => {
      return new Promise(resolve => {
        calendar.loadServices().then(calendarServices => {
          let selectedServiceIds = this.props.selectedServiceCodes
              .map(serviceCode =>
                  Object.values(calendarServices).find(
                      service => service.label === serviceCode
                  )
              )
              .filter(service => service !== undefined)
              .map(service => service.id);

          if (
              this.props.selectableServices !== null &&
              ((selectedServiceIds.length > 0 && selectedServiceIds.length === this.props.selectedServiceCodes.length) ||
                  (this.props.selectedServiceCodes.length === 0 &&
                      Object.values(calendarServices).length === 0 &&
                      this.props.doNotComputeWithoutService !== true))
          ) {
            this.setState(prevState => ({
              loadingSuggestions: true,
              requestsInProgress: prevState.requestsInProgress + 1
            }));
            let url;
            if (ProgendaContext.getContext() === "ADMIN") {
              url = PI.adminCalendarSuggestionsPath({
                calendar_id: calendar.id,
                service_id: selectedServiceIds.join(","),
                split_type: this.props.splitType
              });
            } else {
              url = PI.calendarSuggestionsPath({
                calendar_id: calendar.id,
                service_id: selectedServiceIds.join(","),
                split_type: this.props.splitType
              });
            }
            PI.get({ url }).then(({ data }) => {
              data.forEach(suggestion => {
                suggestion.calendar = calendar;
                suggestion.start = moment(suggestion.start);
                suggestion.stop = moment(suggestion.stop);
                const urlParams = {
                  calendar_id: calendar.slug,
                  service_id: selectedServiceIds.join(","),
                  start: suggestion.start.format("YYYY-MM-DDTHH:mm"),
                  back_to: this.props.backTo,
                  back_to_search_view: this.props.searchView,
                  ...ProgendaUtils.getAllowedParams()
                };
                if (ProgendaContext.getContext() === "ADMIN") {
                  suggestion.url = PI.newAdminCalendarAppointmentPath({
                    ...urlParams,
                    end: suggestion.stop.format("YYYY-MM-DDTHH:mm")
                  });
                } else {
                  suggestion.url = PI.newCalendarAppointmentPath({
                    ...urlParams,
                    now: suggestion.computedAt
                  });
                }
                suggestions.push(suggestion);
              });

              this.setState(prevState => {
                let updateObj = { requestsInProgress: prevState.requestsInProgress - 1 }
                if(prevState.requestsInProgress === 1) {
                  updateObj.loadingSuggestions = false;
                }
                return updateObj;
              });
              resolve()
            })
          }
          else {
            resolve();
          }
        })
      });
    });
    this.setState({loadingServices: true})
    Promise.all(promisesCalendarSuggestions).then(() => {
      this.setState({
        unfilteredSuggestions: suggestions,
        loadingServices: false
      });
    });
  }
  filterSuggestions() {
    const suggestions = this.state.unfilteredSuggestions
        .filter(
            suggestion =>
                this.props.dayFilter.length === 0 ||
                this.props.dayFilter.includes(suggestion.start.day())
        )
        .filter(
            suggestion =>
                this.props.hourFilter.length === 0 ||
                this.props.hourFilter.includes(suggestion.start.hours())
        )
        .filter(
            suggestion =>
                this.props.postcodeFilter.length === 0 ||
                this.props.postcodeFilter.some(postcode =>
                    suggestion.calendar.postcodes.includes(postcode)
                )
        )
        .filter(
            suggestion =>
                this.props.minTime === null ||
                this.props.minTime.isBefore(suggestion.start)
        );
    this.setState({ suggestions: suggestions });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
        this.props.selectedCalendars &&
        this.props.selectedServiceCodes &&
        // A change in selected calendars will entail a change in selectable services
        /*this.props.selectedCalendars !== prevProps.selectedCalendars
                  ||*/
        (this.props.selectedServiceCodes !== prevProps.selectedServiceCodes ||
            !isEqual(this.props.selectableServices, prevProps.selectableServices) ||
            this.state.isMounted && !prevState.isMounted)
    ) {
      this.computeSuggestions();
    } else if (
        this.state.unfilteredSuggestions &&
        (this.state.unfilteredSuggestions !== prevState.unfilteredSuggestions ||
            this.props.dayFilter !== prevProps.dayFilter ||
            this.props.hourFilter !== prevProps.hourFilter ||
            this.props.postcodeFilter !== prevProps.postcodeFilter ||
            this.props.minTime !== prevProps.minTime)
    ) {
      this.filterSuggestions();
    }
  }

  render() {
    const childWithProp = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        ...this.props,
        suggestions: this.state.suggestions,
        loadingSuggestions: this.state.loadingSuggestions,
        loadingServices: this.state.loadingServices,
        children: child.props.children
      });
    });

    return childWithProp;
  }
}
