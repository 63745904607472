/* Update : 2019-10-20 16:00 */
let Config = (function(){
    function get() {
        if ($("#preload-config-domain").get(0)) {
            let parsed = JSON.parse($("#preload-config-domain").text())
            let config = {
                publicDomain: parsed["public_domain"],
                host: parsed["host"],
                embeddedHost: parsed["embedded_host"],
                useHelenaEmbeddable: parsed["use_helena_embeddable"],
                useHelenaProDesign: parsed["use_helena_pro_design"]
            }
            return config
        }
        else {
            return {undefined: true}
        }
    }
    let config = "";

    return {
        get: function(){
            if (!config || config.undefined) {
                config = new get();
            }
            return config;
        }
    };
})()

export default Config;