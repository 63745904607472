import ProgendaUtils from "shared/progenda_utils.js";

export default class Redirecter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { seconds: 10 };
  }
  componentDidMount() {
    var interval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState({ seconds: this.state.seconds - 1 });
      } else {
        clearInterval(interval);

        const baseUrl = new URL(this.props.calendar.redirectAfterBooking);
        const allowedParams = ProgendaUtils.getAllowedParams();

        // Merge the existing query parameters with the allowed parameters
        Object.keys(allowedParams).forEach((key) => {
          baseUrl.searchParams.set(key, allowedParams[key]);
        });

        window.location = baseUrl.toString();
      }
    }, 1000);
  }
  render() {
    return (
      <div className="text-center">
        {I18n.get().t("redirect_information", { seconds: this.state.seconds })}
        <br />
        {I18n.get().t("redirect_warning")}
        <br />
        <div className="progenda-spinner" />
      </div>
    );
  }
}
