import Modal from "shared/components/modal.tsx";
import Appointment from "shared/models/appointment.js";
import ProgendaUtils from "shared/progenda_utils.js";
import PI from "services/progenda_interface.js";
import ServiceSelect from "../../../../shared/components/suggesters/service_select";
import Checkbox from "shared/components/checkbox.tsx";
import Service from "../../../../shared/models/service";
import calendar from "../../../../shared/models/calendar";

export default class DragAndDropModal extends React.Component {
  state = {
    event: { start: moment(), end: moment() },
    previousStart: moment(),
    previousEnd: moment()
  };

  setParams = (
      event,
      previousStart,
      previousEnd,
      calendar,
      revertFunc,
      callbackFunc
  ) => {
    this.setState({
      event: event,
      previousStart: previousStart,
      previousEnd: previousEnd,
      allDay: event.allDay,
      calendar: calendar,
      selectedServiceCode: event.serviceCode,
      revertFunc: revertFunc,
      callbackFunc: callbackFunc
    });
  };

  toggleHidePopup = (e, calendar) => {
    PI.patch({
      url: PI.adminCalendarPath(calendar.id),
      data: {
        hideWarningPopup: !calendar.hidePopup
      }
    }).then(
        () => {
          calendar.hidePopup = !calendar.hidePopup;
          this.forceUpdate();
        });
  }

  confirm = () => {
    const { calendar, event, selectedServiceCode, callbackFunc, allDay } = this.state;
    if (calendar.slug === event.calendarSlug) {
      Appointment.editAppointment(
          calendar,
          event.id,
          event.start,
          event.end,
          allDay,
          () => {}
      );
    } else {
      const newCalendarServices = Object.values(calendar.services);

      let selectedServiceId;
      if (newCalendarServices.length > 0) {
        const selectedService = newCalendarServices.find(
            service => service.label === selectedServiceCode
        );
        selectedServiceId =
            selectedService !== undefined ? selectedService.id : undefined;
      }

      PI.patch({
        url: PI.adminCalendarAppointmentPath(event.calendarSlug, {
          id: event.id
        }),
        data: {
          appointment: {
            start: event.start.unix(),
            stop: event.end.unix(),
            moveToCalendarId: calendar.id,
            serviceId: selectedServiceId,
            allDay: allDay
          }
        }
      });
    }
    if (callbackFunc !== undefined) {
      callbackFunc();
    }
    this._modal.hide();
  };

  cancel = () => {
    this.state.revertFunc();
    this._modal.hide();
  };

   changeSelectedService = serviceCode => {
    this.setState({ selectedServiceCode: serviceCode });
  };

  show = () => {
    if (this.props.calendar && this.props.calendar.hidePopup ||
        this.state.calendar && this.state.calendar.hidePopup) {
      this.confirm();
    }
    else {
      this._modal.show();
    }
  };

  render() {
    const {
      previousStart,
      previousEnd,
      event,
      calendar,
      selectedServiceCode,
      allDay
    } = this.state;
    const newStart = event.start;
    const newEnd = event.end;
    const hideOldTime = (previousStart.hours() == 0) && (previousStart.minutes() == 0) && (previousEnd - previousStart) == 24 * 3600 * 1000
    const hideNewTime = (newStart.hours() == 0) && (newStart.minutes() == 0) && (newEnd - newStart) == 24 * 3600 * 1000
    let differentCalendars = false;

    if (calendar !== undefined) {
      differentCalendars = calendar.slug !== event.calendarSlug;
    }

    return (
        <Modal ref={c => (this._modal = c)}>
          {calendar !== undefined && (
              <div>
                <div className="modal-header disable-auto-refresh">
                  <h4 className="modal-title">
                    <span className="fa fa-exchange"></span>&nbsp;
                    {I18n.get().t("title_confirm_drag_and_drop")}
                  </h4>
                </div>
                <div className="modal-body text-center">
                  {I18n.get().t("do_you_want_to_modify_appointment")}
                  <br />
                  <br />
                  {ProgendaUtils.dateFormat(
                      previousStart,
                      "medium_date_format"
                  ).capitalize()}
                  {!hideOldTime ? <span>
              , {I18n.get().t("from")} {previousStart.format("LT")} {I18n.get().t("to")}{" "}
                    {!previousEnd.isSame(previousStart, "day")
                        ? ProgendaUtils.dateFormat(
                            previousEnd,
                            "medium_date_format"
                        ).capitalize()
                        : ""}{" "}
                    {previousEnd.format("LT")}</span>  : <span></span> }

                  <br />
                  <span className="fa fa-arrow-down"></span>
                  <br />
                  {ProgendaUtils.dateFormat(
                      newStart,
                      "medium_date_format"
                  ).capitalize()}
                  {!hideNewTime ? <span>
              , {I18n.get().t("from")} {newStart.format("LT")} {I18n.get().t("to")}{" "}
                    {!newEnd.isSame(newStart, "day")
                        ? ProgendaUtils.dateFormat(
                            newEnd,
                            "medium_date_format"
                        ).capitalize()
                        : ""}{" "}
                    {newEnd.format("LT")}</span>  : <span></span> }
                  <br />
                  <br />
                  {differentCalendars &&
                  Object.values(calendar.services).length > 0 && (
                      <div>
                        {I18n.get().t("drag_and_drop_select_service")}
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-sm-offset-3 col-sm-6">
                            <ServiceSelect
                                selectedServiceCode={selectedServiceCode}
                                services={Object.values(calendar.services).sort(Service.sortServices)}
                                onChange={this.changeSelectedService}
                                prefillService={calendar.prefillService}
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                  )}
                  <span className="btn btn-sm btn-default" onClick={this.cancel}>
                {I18n.get().t("cancel")}
              </span>
                  &nbsp;&nbsp;
                  <span className="btn btn-sm btn-primary" onClick={this.confirm}>
                {I18n.get().t("confirm")}
              </span>
                  <br />
                  <br />
                </div>
                <div className="modal-footer warning-footer">
                  <i className="fa fa-exclamation-triangle"></i>&nbsp;
                  {I18n.get().t("drag_and_drop_warning", {
                    contact_designation: I18n.get().t(
                        calendar.contactDesignation + "_plural"
                    )
                  })}
                  <Checkbox
                      label= {I18n.get().t("do_not_show_anymore")}
                      checked={calendar.hidePopup}
                      onChange={e => this.toggleHidePopup(e, calendar)}
                      layout="v"
                  />
                </div>
              </div>
          )}
        </Modal>
    );
  }
}
